<script>
import { required } from "vuelidate/lib/validators";
import appConfig from "@/app.config";
import { mapMutations } from "vuex";
import Auth from '@/services/Auth'
import Swal from "sweetalert2";
import Customers from '@/services/Customers'
import ModalChangePassword from "@/components/modals/account/modalChangePassword";

//import images from '../ui/images.vue';

/**
 * Login component
 */
export default {
components: {ModalChangePassword },
  page: {
    title: "Login",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
        username: "",
        password: "",
        secret_code: "",
        submitted: false,
        authError: null,
        tryingToLogIn: false,
        isAuthError: false,
        login_image_url: process.env.VUE_APP_LOGIN_IMAGE_URL,
        background: process.env.VUE_APP_LOGIN_IMAGE_URL,
        tenant: process.env.VUE_APP_TENANT,
        secretCodeReqired: false,
        show2faError: true,
        userIP: '',
        userAgent: '',
        showPassword: false, 
    };
  },
  validations: {
      username: {
          required,
      },
      password: {
          required,
      },
  },
  computed: {
      notification() {
          return this.$store ? this.$store.state.notification : null;
      },
      notificationAutoCloseDuration() {
          return this.$store && this.$store.state.notification ? 5 : 0;
      },
      version() {
        return process.env.VUE_APP_VERSION;
      },
  },
  mounted() {
      document.body.classList.add("authentication-bg");
      this.focusInput()

    this.getUserIP()
      .then(result => {
        if (result) {
          this.userIP = result.ipAddress
          this.userAgent = result.userAgent
          // You can use these values as needed
        } else {
          this.userIP = ''
          this.userAgent = ''
        }
      });
  },
  methods: {
    ...mapMutations(["setUser", "setAccessToken","setRefreshToken"]),
    // Try to log the user in with the username
    // and password they provided.
    async tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {

            this.tryingToLogIn = true;
            this.authError = null;
            this.isAuthError = false;

            try {
                const response = await Auth.login({
                  username: this.username, 
                  password: this.password,
                  secret_code: this.secret_code,
                  user_ip: this.userIP,
                  user_agent: this.userAgent
                })
                localStorage.setItem("access_objects", '');
                localStorage.setItem("user_id", response.data.user_id);
                localStorage.setItem("username", this.username);
                localStorage.setItem("access_token", response.data.tokens.access_token);
                localStorage.setItem("expire", response.data.tokens.expire);
                localStorage.setItem("csrf_token", (typeof response.data.csrf_token != 'undefined') ? response.data.csrf_token : '' );
                localStorage.setItem("message2FA", (typeof response.data.message2FA != 'undefined') ? response.data.message2FA : '' );
                localStorage.setItem("userRole", (typeof response.data.role != 'undefined') ? response.data.role : '' );
                await this.getNavigationMenu(response.data.user_id)

                var message2FA =  (typeof response.data.message2FA != 'undefined') ? response.data.message2FA : ''
                if(message2FA == '2FA_REQUIRED'){
                    Swal.fire({
                        icon: 'info',
                        title: 'Two-factor authentication (2FA) Missing !',
                        text: 'Configure 2FA before continue using Admin Panel',
                        confirmButtonText: 'Setup 2FA',
                    }).then((result) => {
                        if(typeof response.data.force_password_change != 'undefined' && response.data.force_password_change == 1){
                            this.callModalChangePassword();
                        } else {
                          this.isAuthError = false;
                          //this.$router.push({  path: "profile" });
                        }
                    })
                }else{
                  this.tryingToLogIn = false;
                  if(typeof response.data.force_password_change != 'undefined' && response.data.force_password_change == 1){
                      this.callModalChangePassword();
                  } else {
                      this.isAuthError = false;
                      this.$router.push({name: "home"});
                  }
                }

                if(this.tenant != 'MON'){
                  try {
                    const response = await Customers.getCustomersRequests('showHistory='+0);
                    const totalRows = response.data.data.length;

                    // Set the totalRows data in the Vuex store
                    this.$store.commit('setTotalRows', totalRows);

                    // Store totalRows in localStorage for global availability
                    localStorage.setItem('totalRows', totalRows);

                  } catch (error) {
                    console.error('Failed to fetch totalRows data on login:', error);
                  }
                }
            } catch (error) {
                this.tryingToLogIn = false;
                this.authError = error.response.data.error ? error.response.data.error : "";
                this.isAuthError = true;
                if (typeof error.response.data.error_code !== "undefined" && error.response.data.error_code=='2FAERROR') {
                  if (this.authError.includes('Missing') || this.authError.includes('secret_code')) {
                    this.show2faError = false
                  } else {
                    this.show2faError = true
                  }

                    this.secretCodeReqired = true;
                }else{
                    this.secretCodeReqired = false;
                  this.show2faError = true
                }
            }
        
      }
    },
    focusInput() {
      this.$refs.username.$el.focus();
    },

     callModalChangePassword() {
        this.$bvModal.show("change_password");
     },
     toggleShowPassword() {
      this.showPassword = !this.showPassword;
     },
  },
};
</script>

<template>
  <div>
    
    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <router-link to="/" class="mb-1 d-block auth-logo">
                <img
                  :src="background"
                  alt
                  height="150"
                  class="logo logo-dark"
                />
                <img
                  :src="background"
                  alt
                  height="150"
                  class="logo logo-light"
                />
              </router-link>
            </div>
          </div>
        </div>
        <div class="row align-items-center justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Sign in to Admin</h5>
                </div>
                <div class="p-2 mt-4">
                  <b-alert
                    v-if="show2faError"
                    v-model="isAuthError"
                    variant="danger"
                    class="mt-3"
                    dismissible
                    >{{ authError }}</b-alert
                  >

                  <div
                    v-if="notification.message"
                    :class="'alert ' + notification.type"
                  >
                    {{ notification.message }}
                  </div>

                  <b-form @submit.prevent="tryToLogIn">
                    <div v-show="!secretCodeReqired">
                      <b-form-group
                        id="input-group-1"
                        class="mb-3"
                        label="Username"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          ref="username"
                          v-model="username"
                          type="text"
                          placeholder="Enter Username"
                          :class="{ 'is-invalid': submitted && $v.$error }"
                        ></b-form-input>
                          <div v-if="submitted && $v.username.$error" class="invalid-feedback">
                              <span v-if="!$v.username.required">Username is required.</span>
                          </div>
                      </b-form-group>

                      <b-form-group id="input-group-2" class="mb-3">
                        <div class="float-end">
                          <!-- <router-link
                            to="/account/forgot-password"
                            class="text-muted"
                            >Forgot password?</router-link
                          > -->
                        </div>
                        <label for="input-2">Password</label>
                        <b-input-group>
                          <b-form-input
                            id="input-2"
                            v-model="password"
                            :type="showPassword ? 'text' : 'password'"
                            placeholder="Enter password"
                            :class="{ 'is-invalid': submitted && $v.password.$error }"
                             > </b-form-input>
                         <b-input-group-append>
                            <b-button variant="btn btn-outline-secondary" @click="toggleShowPassword">
                              <i :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                        <div
                          v-if="submitted && !$v.password.required"
                          class="invalid-feedback"
                        >
                          Password is required.
                        </div>
                      </b-form-group>
                    </div>

                    <b-form-group id="input-group-3" class="mb-3" v-show="secretCodeReqired">
                        <div class="float-end">
                        </div>
                        <label for="input-3">Two-factor authentication (2FA)</label>
                        <b-form-input
                          id="input-3"
                          v-model="secret_code"
                          type="text"
                          placeholder="Secret Code"
                        ></b-form-input>
                    </b-form-group>
                    <!-- <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="auth-remember-check"
                      />
                      <label class="form-check-label" for="auth-remember-check"
                        >Remember me</label
                      >
                    </div> -->
                    <div class="mt-3">
                      <b-button type="submit" variant="primary" class="w-100" :disabled="tryingToLogIn">
                        <b-spinner v-show="tryingToLogIn" small></b-spinner>
                        Log In
                      </b-button>
                      <b-button variant="secondary" class="mt-3 w-100" :disabled="tryingToLogIn" v-show="secretCodeReqired" @click="reloadPage">
                        Cancel
                      </b-button>

                    </div>
                   
                  </b-form>
                </div>
                <!-- end card-body -->
              </div>
              <!-- end card -->
            </div>
            <div class="mt-5 text-center">
              <p>
                Powered by 
                <span>
                  <a href="https://www.azerion.com/" target="_blank">Azerion</a>
                </span>
                <span class="d-block small">Version: {{version}}</span>
              </p>

            </div>
            <!-- end row -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
    </div>
    <ModalChangePassword></ModalChangePassword>
  </div>
</template>

<style lang="scss" module></style>



